import React from "react";
import PageBanner from "../elements/banner";
import Chip from '@mui/material/Chip';
import MenuRow from '../elements/menuRow';

import { ReactComponent as Beer } from '../../static/icons/beer.svg';
import { ReactComponent as Fries } from '../../static/icons/fries.svg';
import { ReactComponent as Hamburger } from '../../static/icons/hamburger.svg';

import menu from "../../lib/menu";
import '../../style/layout/menu.scss'

export default class MenuPage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      activeContent: 'foodContent'
    }
  }

  handleChipClick(currentTargetSection) {
    this.setState({activeContent: currentTargetSection});
  };

  render() {
    return (
      <>
        <div className={this.state.activeContent === 'foodContent' ? 'show' : 'hide'}>
          <PageBanner image={require('../../static/images/menu1.jpeg')} title="Piatti" description="Tutti gli hamburger sono serviti con patatine fritte!" color="white" position="top"/>
        </div>
        <div className={this.state.activeContent === 'friedContent' ? 'show' : 'hide'}>
          <PageBanner image={require('../../static/images/fried.jpg')} title="Fritti" color="white"/>
        </div>
        <div className={this.state.activeContent === 'beverageContent' ? 'show' : 'hide'}>
          <PageBanner image={require('../../static/images/beer.jpeg')} title="Bevande" description="Birre, cocktail, aperitivi, shot e bibite!" color="white"/>
        </div>
        
        <div className="chips-wrapper">
          <Chip label="Piatti" className={this.state.activeContent === 'foodContent' ? 'white-color background-dark active' : 'primary-color background-light'} color="primary" icon={<Hamburger/>} variant="outlined" onClick={() => this.handleChipClick('foodContent')}/>
          <Chip label="Fritti" className={this.state.activeContent === 'friedContent' ? 'white-color background-dark active' : 'primary-color background-light'} color="primary" icon={<Fries/>} variant="outlined" onClick={() => this.handleChipClick('friedContent')}/>
          <Chip label="Bevande" className={this.state.activeContent === 'beverageContent' ? 'white-color background-dark active' : 'primary-color background-light'} color="primary" icon={<Beer/>} variant="outlined" onClick={() => this.handleChipClick('beverageContent')}/>
        </div>
        <div className="main-content">
          {/* <div className="btn-primary order-link">
            <a className="unstyled-link" href="">
              <h4 className="tertiary-color secondary-font underline">Clicca qui per ordinare online dal menu stand up o dallo stand gastronomico!</h4>
            </a>
          </div> */}
          <div id="foodContent" className={(this.state.activeContent === 'foodContent' ? 'show' : 'hide') + ' section-content'}>
            <p className="section-disclaimer secondary-font primary-color">
              Prova tutti gli hamburger anche in <b>versione double</b> con doppia carne a soli <b>11€</b>!
            </p>
            {
              menu.food.constructor === Array ?
                menu.food.map((item) => {
                  return (
                    <MenuRow item={item}/>
                  )
                })
              :
                Object.keys(menu.food).map((section) => {
                  return (
                    <>
                      <h1 className="primary-font secondary-color menu-title">{section}</h1>
                      {
                        menu.food[section].map((item) => {
                          return (
                            <MenuRow item={item}/>
                          )
                        })
                      }
                    </>
                  )
                })
            }
          </div>
          <div id="friedContent" className={(this.state.activeContent === 'friedContent' ? 'show' : 'hide') + ' section-content'}>
          {
              menu.fried.constructor === Array ?
                menu.fried.map((item) => {
                  return (
                    <MenuRow item={item}/>
                  )
                })
              :
                Object.keys(menu.fried).map((section) => {
                  return (
                    <>
                      <h1 className="primary-font secondary-color menu-title">{section}</h1>
                      {
                        menu.fried[section].map((item) => {
                          return (
                            <MenuRow item={item}/>
                          )
                        })
                      }
                    </>
                  )
                })
            }
          </div>
          <div id="beverageContent" className={(this.state.activeContent === 'beverageContent' ? 'show' : 'hide') + ' section-content'}>
          {
              menu.beverage.constructor === Array ?
                menu.beverage.map((item) => {
                  return (
                    <MenuRow item={item}/>
                  )
                })
              :
                Object.keys(menu.beverage).map((section) => {
                  return (
                    <>
                      <h1 className="primary-font secondary-color menu-title">{section}</h1>
                      {
                        menu.beverage[section].map((item) => {
                          return (
                            <MenuRow item={item}/>
                          )
                        })
                      }
                    </>
                  )
                })
            }
          </div>
        </div>
      </>
    );
  };
};