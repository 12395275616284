import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function CardImgAndText(props) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea href={props.url}>
        <CardMedia
          component="img"
          height="140"
          image={props.image}
          alt={props.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className='primary-font primary-color'>
            {props.title}
          </Typography>
          <Typography variant="body2" className='secondary-font secondary-color bold'>
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}