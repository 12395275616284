import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';

function getItemPrice(item) {
    return (
        item.price ?
            item.price
        : 
            <div className="prices-wrapper">
                {
                    item.priceSmall ?
                        <div className="min-price">
                            <span className="price-label">{item.labelSmall}</span>
                            <span className="price-value">{item.priceSmall}</span>
                        </div>
                    :
                        ''
                }
                {
                    item.priceMedium ?
                        <div className="max-price">
                            <span className="price-label">{item.labelMedium}</span>
                            <span className="price-value">{item.priceMedium}</span>
                        </div>
                    :
                        ''
                }
            </div>
    );
}

export default function MenuRow(props) {
    var item = props.item;

    if(item.description || item.allergens || item.tastes) {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={
                        <div className="item-price primary-color secondary-font">
                            {
                                getItemPrice(item)
                            }
                        </div>
                    }
                    aria-controls={item.name.replaceAll(' ', '_') + '-content'}
                    id={item.name.replaceAll(' ', '_') + '-header'}
                >
                    <div className="expand-icon">
                        <ExpandMore color="primary" fontSize="medium"/>
                    </div>
                    <div className="item-title primary-color secondary-font uppercase">
                        {item.name}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="item-description-wrapper">
                    {
                        item.description ?
                            <p className={'secondary-font primary-color item-description ' + (item.tastes ? 'spaced' : '')}>
                                {item.description}
                            </p>
                        :
                            ''
                    }
                    {
                        item.tastes ?
                            <ul className="item-tastes">
                                {
                                    item.tastes.map((taste) => (
                                        <li className="item-taste secondary-font primary-color">{taste}</li>
                                    ))
                                }
                            </ul>
                        :
                            ''
                    }
                    {
                        item.allergens ?
                            <p className="secondary-font primary-color item-allergens">
                                {item.allergens}
                            </p>
                        :
                            ''
                    }
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    } else {
        return (
            <div className="item-wrapper">
                <div className="item-name primary-color secondary-font uppercase">
                    {item.name}
                </div>
                <div className="item-price primary-color secondary-font">
                    {
                        getItemPrice(item)
                    }
                </div>
            </div>
        )
    }
}