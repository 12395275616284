import React from "react";
import CardImgAndText from "../elements/card";
import homepageElements from '../../lib/homepage';
import { Link } from "react-router-dom";

import "../../style/layout/homepage.scss"

export function HomePage() {
  return (
    <div className="homepage-content">
      {homepageElements.navigation.map((item) => (
        <Link to={item.url} className="unstyled-link homepage-element">
          <CardImgAndText image={require(`../../${item.image}`)} title={item.title} description={item.description}></CardImgAndText>
        </Link>
      ))}
    </div>
  );
}