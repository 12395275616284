import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Backdrop from '@mui/material/Backdrop';
import Close from '@mui/icons-material/Close';

import "../../style/elements/imagesList.scss";

export default class ImageGrid extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            activeContent: ''
        }
        this.itemData = props.items;
    }

    handleModals(event, currentTargetSection) {
    event.stopPropagation();
    this.setState({activeContent: currentTargetSection});
    };
    
    render() {
    return (
        <div className='images-grid-wrapper'>
            {
                this.itemData.map((item) => {
                    var bigTile = item.topSponsor,
                        modalID = item.title.replaceAll(' ', '_');

                    return (
                        <>
                            <div className={(bigTile ? 'big-tile' : 'small-tile') + ' image-grid-item-wrapper'} onClick={(event) => this.handleModals(event, modalID)}>
                                <ImageListItem key={item.title.replaceAll(' ', '_')}>
                                    <img
                                        src={require(`../../${item.img}`)}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar className='secondary-font primary-color'
                                        title={item.title}
                                    />
                                </ImageListItem>
                            </div>
                            {
                                    item.description || item.contacts ? 
                                        <Backdrop
                                            sx={{ color: '#124869', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={this.state.activeContent === modalID}
                                        >
                                            <div className={(this.state.activeContent === modalID ? 'open ' : '') + ' modal-window secondary-font'}>
                                                <div className='close-icon-wrapper'>
                                                    <Close onClick={(event) => this.handleModals(event, '')}/>
                                                </div>
                                                <h3>{item.title}</h3>
                                                {
                                                    item.description ?
                                                        <p>{item.description}</p>
                                                    :
                                                        ''
                                                }
                                                {
                                                    item.contacts ?
                                                        <>
                                                            <h4 className='secondary-color'>Contatti</h4>
                                                            {
                                                                item.contacts.constructor === Array ? 
                                                                    item.contacts.map((contact) => {
                                                                        return <p>{contact}</p>
                                                                    })
                                                                :
                                                                    <p>{item.contacts}</p>
                                                            }
                                                        </>
                                                    :
                                                        ''
                                                }
                                            </div>
                                        </Backdrop>
                                    :
                                        ''
                                }
                        </>
                    )
                })
            }
        </div>
    )}
}