import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const theme = createTheme({
  palette: {
    primary: {
      light: '#fdfffc',
      main: '#124869',
      dark: '#124869',
      contrastText: '#f3a64c'
    },
    secondary: {
      light: '#fdfffc',
      main: '#f3a64c',
      dark: '#124869',
      contrastText: '#124869',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App/>
    </ThemeProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();