import React from 'react';

// routing variables
import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/scrollToTop';

// main layout components
import DrawerAppBar from './components/layout/navbar';
import CssBaseline from '@mui/material/CssBaseline';
import './App.scss';

// main pages components
import { HomePage } from './components/pages/home';
import MenuPage from './components/pages/menu';
import { EventsPage } from './components/pages/events';
import { NotFoundPage } from './components/errors/404';
import { ContactUsPage } from './components/pages/contactus';
import { SponsorPage } from './components/pages/sponsor';
import BottomNavigation from './components/elements/bottomNavigation';

function App() {
  return (
    <div>
      <BrowserRouter>
        <CssBaseline enableColorScheme />
        <DrawerAppBar/>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage/>} errorElement={<NotFoundPage/>}/>
          <Route path="/menu" element={<MenuPage/>} errorElement={<NotFoundPage/>}/>
          <Route path="/events" element={<EventsPage/>} errorElement={<NotFoundPage/>}/>
          <Route path="/contactus" element={<ContactUsPage/>} errorElement={<NotFoundPage/>}/>
          <Route path="/sponsor" element={<SponsorPage/>} errorElement={<NotFoundPage/>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
        <BottomNavigation/>
      </BrowserRouter>
    </div>
  );
}

export default App;
