
import React from "react";
import { Link } from 'react-router-dom';

export function NotFoundPage() {
  return (
    <div className="main-content">
      <div className="page-title primary-font primary-color">
        <h1>OOOPS! QUI NON C'È NIENTE!</h1>
      </div>
      <Link to="/" className="unstyled-link">
        <div className="back-home btn-primary">
          <h3 className="tertiary-color secondary-font">Torna alla home</h3>
        </div>
      </Link>
    </div>
  );
}