import React from "react";
import ImageGrid from "../elements/imagesList";

import sponsorElements from '../../lib/sponsor';

export function SponsorPage() {
  return (
    <div className="main-content">
        <div className="page-title">
            <h1 className="primary-font primary-color">I nostri sponsor</h1>
        </div>
        <ImageGrid items={sponsorElements.sponsors}/>
    </div>
  );
}