import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

import logo from '../../logo.png';
import '../../style/layout/navbar.scss';

function DrawerAppBar(props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 2 }}
          >
            <Link to="/" className="unstyled-link">
              <div className="logo-wrapper">
                <img src={logo} alt="standup-logo" width="70" height="70"/>
                <div className='logo-name-wrapper primary-color primary-font'>
                  <h1>STAND UP</h1>
                  <h3>SPAZIO GIOVANI CREOLA</h3>
                </div>
              </div>
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ p: 3 }}></Box>
    </Box>
  );
}

export default DrawerAppBar;
