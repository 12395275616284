import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { NavLink } from "react-router-dom";

// icons
import { ReactComponent as Calendar } from '../../static/icons/calendar-day.svg';
import { ReactComponent as Contact } from '../../static/icons/contact.svg';
import { ReactComponent as Handshake } from '../../static/icons/handshake.svg';
import { ReactComponent as Menu } from '../../static/icons/utensils.svg';

import '../../style/elements/bottomNavigation.scss';

export default function FixedBottomNavigation() {
  const ref = React.useRef(null);

  return (
    <Box sx={{ pb: 7 }} ref={ref} className='bottom-navigation'>
      <CssBaseline />
        <div className='bottom-navigation-wrapper'>
            <NavLink className='secondary-font nav-link' to='/menu'>
                <Menu/>
                <span>Menu</span>
            </NavLink>
            <NavLink className='secondary-font nav-link' to='/events'>
                <Calendar/>
                <span>Eventi</span>
            </NavLink>
            <NavLink className='secondary-font nav-link' to='/sponsor'>
                <Handshake/>
                <span>Sponsor</span>
            </NavLink>
            <NavLink className='secondary-font nav-link' to='/contactus'>
                <Contact/>
                <span>Contatti</span>
            </NavLink>
        </div>
    </Box>
  );
}