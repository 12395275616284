import React from "react";

// icons
import { ReactComponent as Mail } from '../../static/icons/mail.svg';
import { ReactComponent as Facebook } from '../../static/icons/facebook.svg';
import { ReactComponent as Instagram } from '../../static/icons/instagram.svg';
import { ReactComponent as Web } from '../../static/icons/web.svg';
import { ReactComponent as Marker } from '../../static/icons/marker.svg';

import "../../style/layout/contactus.scss";

export function ContactUsPage() {
  return (
    <div className="main-content">
        <div className="page-title">
            <h1 className="primary-font primary-color">Contattaci</h1>
        </div>
        <div className="contacts-wrapper">
          <a className="contact-wrapper secondary-font primary-color" href="mailto:spaziogiovani.creola@gmail.com">
            <Mail/>
            <h5>spaziogiovani.creola@gmail.com</h5>
          </a>
          <a className="contact-wrapper secondary-font primary-color" href="https://m.facebook.com/SpazioGiovaniCreolaStandUp" target="_blank" rel="noreferrer">
            <Facebook/>
            <h5>Spazio Giovani Creola Stand Up</h5>
          </a>
          <a className="contact-wrapper secondary-font primary-color" href="https://www.instagram.com/standup_spaziogiovanicreola" target="_blank" rel="noreferrer">
            <Instagram/>
            <h5>@standup_spaziogiovanicreola</h5>
          </a>
          <a className="contact-wrapper secondary-font primary-color" href="https://www.instagram.com/parrocchia.creola" target="_blank" rel="noreferrer">
            <Instagram/>
            <h5>@parrocchia.creola</h5>
          </a>
          <a className="contact-wrapper secondary-font primary-color" href="https://www.parrocchiacreola.it" target="_blank" rel="noreferrer">
            <Web/>
            <h5>parrocchiacreola.it</h5>
          </a>
          <a className="contact-wrapper secondary-font primary-color unstyled-link" href="https://maps.app.goo.gl/EHRV2VJxi3XYJxd68" target="_blank" rel="noreferrer">
            <Marker/>
            <div className="location-wrapper">
              <h5>Via Molini</h5>
              <h6>Creola (Saccolongo), Padova</h6>
            </div>
          </a>
        </div>
    </div>
  );
}