
import React from "react";
import PageBanner from "../elements/banner";
import EventCard from "../elements/eventCard";
import eventsList from "../../lib/events";

import '../../style/elements/eventCard.scss'

export function EventsPage() {
  return (
    <>
      <PageBanner image={require('../../static/images/event1.jpg')} title="Eventi" description="Ogni sera musica dal vivo!" color="white" position="top"/>
      <div className="main-content">
        <div className="events-wrapper">
          {
            eventsList.dates.map((event) => (
              <div>
                <EventCard
                  eventCopies={{ title: event.title || '', subtitle: event.subtitle || '', desc: event.description || '' }}
                  eventDate={{ day: event.day, month: event.month }} 
                  eventLogoUrl={ event.logo }
                />
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
}