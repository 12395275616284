import React from "react";
import '../../style/elements/banner.scss';

export default function PageBanner(props) {
    var colorClass = props.color ? props.color + '-color' : 'primary-color';

    return (
        <div className="page-banner-wrapper">
            <img className={'page-banner-image ' + (props.position ? props.position : '')} src={props.image} alt={props.title}/>
            <div className="page-banner-mask"></div>
            <div className="page-banner-text-wrapper">
                {
                    props.title ?
                        <h1 className={colorClass + ' page-banner-title'}>
                            {props.title}
                        </h1>
                    : ''
                }
                {
                    props.description ?
                        <h3 className={colorClass + ' page-banner-description'}>
                            {props.description}
                        </h3>
                    : ''
                }
            </div>
        </div>
    )
}