import React from 'react';
import DateCard from './date';

export default function EventCard(props) {
  return (
    <div className="event-card-wrapper primary-font">
        <DateCard day={props.eventDate.day} month={props.eventDate.month} />
        <div className='event-card-copies'>
            <div className='event-card-copies-titles'>
                <h3 className='primary-color'>{props.eventCopies.title}</h3>
                {
                    props.eventCopies.subtitle ?
                        <h4 className='primary-color'>{props.eventCopies.subtitle}</h4>
                    :
                        ''
                }
            </div>
            {
                props.eventCopies.desc ?
                    <h5 className='event-card-copies-desc secondary-color secondary-font'>{props.eventCopies.desc}</h5>
                :
                    ''
            }
        </div>
        {
            props.eventLogoUrl ? 
                <div className='event-card-logo'>
                    <img src={require(`../../${props.eventLogoUrl}`)} alt={props.eventCopies.title} width='45' height='45'/>
                </div>
            :
                ''
        }
    </div>
  );
}